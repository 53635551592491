<template>

  <b-card class="logo-card">

    <div class="bg-logo"></div>

    <h1 class="text-primary welcome-text mt-5">Welcome to {{appName}}</h1>

  </b-card>
</template>

<script>
import { BRow, BCol , BCard } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import appName from "@/main";

export default {
  title:"Home",
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      appName,
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
.logo-card{
  height: 80vh;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-items: center;
  //justify-content: center;
  align-items: center;
}
.bg-logo{
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
  height: 20rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../../../../src/assets/images/logo/logo.png");
}
.welcome-text{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 900;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
